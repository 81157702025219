header {
  background: #000;
  width: 100%;
  height: 60px;
  .menu {
    max-width: $max-width;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      li {
        margin-right: 2rem;
        a {
          color: #fff;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            text-underline-offset: 3px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
