footer {
  background: #000;
  width: 100%;
  height: 50px;
  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: $max-width;
    margin: auto;
    height: 100%;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      li {
        margin: 0 1rem;
        a {
          color: #fff;
          text-decoration: none;
          font-size: 0.9rem;
          &:hover {
            text-decoration: underline;
            text-underline-offset: 3px;
          }
        }
      }
    }
    p {
      color: #fff;
      margin: 0;
      color: #fff;
      font-size: 0.8rem;
    }
  }
}
